import { createRouter, createWebHistory } from 'vue-router'

import store from './store/store.js'

import { getProjectPathCookie } from '@/assets/js/helper.js'

const routes = [
  {
    path: '/',
    name: 'Explore',
    component: () => import('./pages/Model.vue'),
    link: true,
    mobile: true,
    presentationMode: true,
    publicMode: true
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('./pages/pageNotFound.vue'),
    link: false
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import('./pages/About.vue'),
  //   link: true,
  //   mobile: true,
  //   presentationMode: true,
  //   publicMode: true
  // }
  // {
  //   path: '/filter',
  //   name: 'Filter',
  //   component: () => import('./pages/Filter.vue'),
  //   link: true,
  //   meta: {
  //     requiresModelData: true
  //   },
  //   mobile: false,
  //   presentationMode: true,
  //   publicMode: true
  // },
  // {
  //   path: '/compare',
  //   name: 'Compare',
  //   component: () => import('./pages/Compare.vue'),
  //   link: true,
  //   mobile: false,
  //   presentationMode: true,
  //   publicMode: true
  // },
  // {
  //   path: '/narrative',
  //   name: 'Narrative',
  //   component: () => import('./pages/Narrative.vue'),
  //   link: true,
  //   mobile: false,
  //   presentationMode: false,
  //   publicMode: false
  // },
  // {
  //   path: '/collection',
  //   name: 'Collection',
  //   component: () => import('./pages/Collection.vue'),
  //   link: true,
  //   mobile: false,
  //   presentationMode: true,
  //   publicMode: true
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresModelData)) {
    if (!store.getters.getModelDataFlag) {
      next({
        path: '/',
        query: {
          project: getProjectPathCookie()
        }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})
export default router

import { createApp } from '@vue/compat'
import App from './App.vue'
import router from './router'
import store from './store/store'

// Bootstrap import
// import BootstrapVue from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

// Vue.use(BootstrapVue)

const app = createApp(App)
app.use(router)
app.use(store)
app.mount('#app')

// import {
//   Color
// } from 'three'

export const colorList = {
  MeshBasicMaterial: {
    colors: {},
    options: {}
  },
  MeshStandardMaterial: {
    colors: {},
    options: {
      metalness: 0.5,
      roughness: 1
    }
  }
}

// upload folders default
export let uploadFolders = [
  {
    name: 'context.json',
    description: 'static context',
    note: 'file size <= 100mb',
    progress: 0,
    totalFiles: 1,
    icon: 'file'
  },
  {
    name: 'settings.json',
    description: 'scout settings',
    note: '',
    progress: 0,
    totalFiles: 1,
    icon: 'file'
  },
  {
    name: 'data.csv',
    description: 'data matrix',
    note: '',
    progress: 0,
    totalFiles: 1,
    icon: 'file'
  },
  {
    name: 'models',
    description: 'model options',
    note: '',
    progress: 0,
    totalFiles: 5000,
    icon: 'folder'
  }
]

// custom drag and drop data stored here
export let customData = {}
// scene default black color
// export const sceneBlack = new Color('rgb(24,24,24)')
// explore mode settings

export const exploreModeSettings = {
  camera: {
    position: {}
  },
  controls: {}
}
